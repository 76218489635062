import { UrlVariablesType } from '../model/helpers.model';

/* eslint-disable no-extend-native, func-names */
export {};

declare global {
  interface String {
    prepareUrl(variables: UrlVariablesType): string;
  }
}

String.prototype.prepareUrl = function (variables: UrlVariablesType) {
  let url = this;

  if (url && variables) {
    Object.entries(variables).forEach(([key, value]) => (url = value ? url.replace(`{${key}}`, value.toString()) : ''));
    url = url.trim();
    url = url.endsWith('/') ? url.substr(0, url.length - 1) : url;
  }

  return url.trim();
};
