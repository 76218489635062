/* eslint-disable no-extend-native, func-names */
export {};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    sortAndFilterByValues: (valuesArr: string[], sortingKey: string) => any[];
  }
}

Array.prototype.sortAndFilterByValues = function (valuesArr, sortingKey) {
  let baseArr = this;

  return valuesArr.map((sortValue) => baseArr.find((item) => item[sortingKey] && item[sortingKey] === sortValue)).filter((item) => item);
};
